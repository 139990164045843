// src/components/CreateBrand.js
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api";

const CreateBrand = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, domain } = location.state || {};

  // Initialize form fields
  const [brandName, setBrandName] = useState("");
  const [website, setWebsite] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [turnoverRange, setTurnoverRange] = useState("");
  const [userName, setUserName] = useState("");
  const [userContact, setUserContact] = useState("");
  const [budget, setBudget] = useState("");

  // State for handling UI feedback
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Ensure email and domain are present
  if (!email || !domain) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-4">Invalid Access</h1>
          <p>Please try logging in again.</p>
        </div>
      </div>
    );
  }

  // Handler for form submission
  const handleCreateBrand = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);
    setErrorMessage("");
  
    // Basic client-side validation
    if (
      !brandName ||
      !website ||
      !userName ||
      !userContact ||
      !turnoverRange ||
      !budget
    ) {
      setErrorMessage("Please fill in all required fields.");
      setLoading(false);
      return;
    }
  
    // Additional client-side validations (e.g., URL format) can be added here
  
    // Prepare the payload
    const payload = {
      brandName,
      website,
      turnoverRange,
      userName,
      userContact,
      budget,
      email,
      domain,
    };
  
    // Conditionally add logoUrl if it's provided and not empty
    if (logoUrl && logoUrl.trim() !== "") {
      payload.logoUrl = logoUrl.trim();
    }
  
    try {
      const response = await api.post("/auth/brand/create-brand", payload);
  
      const { accessToken, error } = response.data;
  
      if (error) {
        throw new Error(error);
      }
  
      if (!accessToken) {
        throw new Error("Access token not received.");
      }
  
      // Store access token in localStorage
      localStorage.setItem("accessToken", accessToken);
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  
      // Navigate to the home page or desired route
      navigate("/");
    } catch (error) {
      console.error("Error Creating Brand:", error);
      setErrorMessage(
        error.response?.data?.error || error.message || "Failed to create brand. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-lg bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Create Your Brand</h1>

        {errorMessage && (
          <div className="text-red-600 mb-4">{errorMessage}</div>
        )}

        <form onSubmit={handleCreateBrand}>
          {/* Brand Name */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="brandName">
              Brand Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="brandName"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>

          {/* Website */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="website">
              Website<span className="text-red-500">*</span>
            </label>
            <input
              type="url"
              id="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
              placeholder="https://example.com"
            />
          </div>

          {/* Logo URL */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="logoUrl">
              Logo URL
            </label>
            <input
              type="url"
              id="logoUrl"
              value={logoUrl}
              onChange={(e) => setLogoUrl(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="https://example.com/logo.png"
            />
          </div>

          {/* Turnover Range */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="turnoverRange">
              Turnover Range<span className="text-red-500">*</span>
            </label>
            <select
              id="turnoverRange"
              value={turnoverRange}
              onChange={(e) => setTurnoverRange(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            >
              <option value="">Select Turnover Range</option>
              <option value="Less than 1Cr">Less than 1Cr</option>
              <option value="1Cr to 10Cr">1Cr to 10Cr</option>
              <option value="10Cr to 50Cr">10Cr to 50Cr</option>
              <option value="More than 50Cr">More than 50Cr</option>
            </select>
          </div>

          {/* User Name */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="userName">
              Your Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>

          {/* User Contact */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="userContact">
              Contact Number<span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              id="userContact"
              value={userContact}
              onChange={(e) => setUserContact(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
              placeholder="+91 12345 67890"
            />
          </div>

          {/* Budget */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="budget">
              Budget (INR)<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              id="budget"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
              min="0"
              placeholder="e.g., 500000"
            />
          </div>

          {/* Hidden Fields (Email and Domain) */}
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="domain" value={domain} />

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded"
            disabled={loading}
          >
            {loading ? "Creating Brand..." : "Create Brand"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateBrand;
