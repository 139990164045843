import axios from "axios";
import { toast } from "react-toastify";

// Create axios instance with base URL and credentials
const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_ENDPOINT || "https://api.retailwise.in",
  withCredentials: true, // Ensure cookies are sent if using cookies for tokens
});

// Function to set the access token in headers
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

// Set the auth token from localStorage on initial load
const accessToken = localStorage.getItem("accessToken");
setAuthToken(accessToken);

let isRefreshing = false;
let refreshSubscribers = [];

// Function to subscribe for token refresh events
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

// Function to notify all subscribers with new token
const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

// Helper function to check if the URL is exempt from token refresh
const isExemptedURL = (url) => {
  const exemptedURLs = ["/", "/auth/brand/login", "/auth/brand/verify"];
  return exemptedURLs.some((exemptedURL) => url.includes(exemptedURL));
};

// Axios response interceptor to handle token refresh and errors
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (isExemptedURL(originalRequest.url)) {
      return Promise.reject(error);
    }

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      isRefreshing = true;
      try {
        // **Corrected Endpoint**
        const refreshResponse = await api.post("/auth/brand/refresh-token");

        if (refreshResponse.status === 200) {
          const newAccessToken = refreshResponse.data.accessToken;
          localStorage.setItem("accessToken", newAccessToken);
          setAuthToken(newAccessToken);
          onRefreshed(newAccessToken);

          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("accessToken");
        setAuthToken(null);
        toast.error("Session expired. Please log in again.");
        window.location.href = "/login";
      } finally {
        isRefreshing = false;
      }
    }

    if (error.response && error.response.status >= 500) {
      toast.error("Server error. Please try again later.");
    } else if (!error.response) {
      toast.error("Network error. Check your internet connection.");
    }

    return Promise.reject(error);
  }
);

export default api;
