import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // To handle loading state
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        console.log("AuthProvider: Attempting to refresh access token...");
        const response = await api.post("/auth/brand/refresh-token");
        console.log("AuthProvider: Refresh token response:", response.data);
        const { accessToken } = response.data;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          setIsAuthenticated(true);
          console.log("AuthProvider: User is authenticated.");
        } else {
          setIsAuthenticated(false);
          console.log(
            "AuthProvider: No access token received. User is not authenticated."
          );
        }
      } catch (error) {
        console.error(
          "AuthProvider: Failed to refresh token:",
          error.response || error
        );
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const logout = async () => {
    try {
      await api.post("/auth/brand/logout");
      console.log("AuthProvider: User logged out.");
    } catch (error) {
      console.error("AuthProvider: Logout failed:", error);
    }
    localStorage.removeItem("accessToken");
    delete api.defaults.headers.common["Authorization"];
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, setIsAuthenticated, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
