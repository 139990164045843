// src/components/EmailLogin.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import api from "../api";

const EmailLogin = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [stateId, setStateId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    let timer;
    if (otpSent && timeLeft > 0) {
      timer = setInterval(() => setTimeLeft((prevTime) => prevTime - 1), 1000);
    } else if (timeLeft === 0 && otpSent) {
      setOtpSent(false);
      setSuccessMessage("You can now resend the OTP.");
    }
    return () => clearInterval(timer);
  }, [otpSent, timeLeft]);

  const validateEmail = (email) => {
    // Simple email regex for validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleRequestOtp = async () => {
    if (!email || !validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      setSuccessMessage("");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await api.post("/auth/brand/login", { email });
      const { state_id } = response.data;

      if (!state_id) throw new Error("Failed to retrieve state ID.");

      setStateId(state_id);
      setOtpSent(true);
      setTimeLeft(30);
      setSuccessMessage("OTP has been sent to your email.");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error || "Failed to send OTP. Please try again."
      );
      console.error("Error Requesting OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setErrorMessage("Please enter the OTP.");
      setSuccessMessage("");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await api.post("/auth/brand/verify", {
        state_id: stateId,
        otp,
      });

      console.log("Response Data:", response.data); // Debugging

      if (response.data.showBrandForm) {
        // Navigate to brand creation page with email and domain
        navigate("/create-brand", {
          state: {
            email: response.data.email,
            domain: response.data.domain,
          },
        });
        return;
      }

      const { accessToken } = response.data;

      if (!accessToken) {
        throw new Error("Access token not received.");
      }

      // Store access token in localStorage
      localStorage.setItem("accessToken", accessToken);
      console.log("Access Token Stored:", localStorage.getItem("accessToken"));
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      // Update AuthContext
      setIsAuthenticated(true);

      // Navigate to home
      navigate("/");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error || "Failed to verify OTP. Please try again."
      );
      console.error("Error Verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = () => {
    setOtpSent(false);
    setOtp("");
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-center mb-6">
          Login with Email OTP
        </h1>

        {errorMessage && (
          <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
            {errorMessage}
          </div>
        )}

        {successMessage && (
          <div className="bg-green-100 text-green-700 p-3 rounded mb-4">
            {successMessage}
          </div>
        )}

        {!otpSent ? (
          <>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                placeholder="you@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <button
              onClick={handleRequestOtp}
              className={`w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition duration-200 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Sending OTP..." : "Request OTP"}
            </button>
          </>
        ) : (
          <>
            <div className="mb-4">
              <label htmlFor="otp" className="block text-gray-700 mb-2">
                One-Time Password (OTP)
              </label>
              <input
                type="text"
                id="otp"
                placeholder="Enter the OTP sent to your email"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>
            <button
              onClick={handleVerifyOtp}
              className={`w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 transition duration-200 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </button>
            <div className="mt-4 flex items-center justify-between">
              <p className="text-sm text-gray-600">
                Time left:{" "}
                <span className="font-semibold text-gray-800">
                  {timeLeft} seconds
                </span>
              </p>
              {!otpSent && (
                <button
                  onClick={handleRequestOtp}
                  className="text-sm text-indigo-600 hover:underline"
                  disabled={loading}
                >
                  Resend OTP
                </button>
              )}
            </div>
            {!otpSent && (
              <button
                onClick={handleResendOtp}
                className="mt-4 w-full bg-indigo-500 text-white py-2 rounded-lg hover:bg-indigo-600 transition duration-200"
              >
                Resend OTP
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmailLogin;
