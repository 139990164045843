import React, { useState } from "react";
import MapComponent from "../components/MapComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EventPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventDates, setEventDates] = useState([]);
  const [filters, setFilters] = useState({
    showUpcoming: true,
    showPast: false,
  });
  const [applyFilters, setApplyFilters] = useState(false);

  const isEventDate = (date) => {
    return eventDates.some(
      (eventDate) =>
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
    );
  };

  const handleApplyFilters = () => {
    setApplyFilters((prev) => !prev);
  };

  return (
    <div className="flex flex-col h-screen w-screen">
      {/* Navbar Section */}
      <nav className="bg-white shadow-md flex items-center shadow-md">
        <div className="container ml-6 flex justify-between items-center h-[90px]">
          <div className="text-4xl font-semibold text-black">
            Check out our <span className="text-[#029E9B]">Event Calendar</span>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex flex-grow">
        {/* Filters Section */}
        <div className="w-full md:w-1/5 bg-gray-100 p-4 md:p-2 shadow-lg overflow-y-auto h-full">
          <div className="border bg-white h-full p-4 space-y-6 overflow-y-auto">
            <h2 className="text-xl font-bold text-[#029E9B] border-b border-[#029E9B] pb-2">
              Filters
            </h2>

            {/* Schedule Filter */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Schedule</h4>
              <label className="block">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters({
                      showUpcoming: !filters.showUpcoming,
                      showPast: filters.showPast ? false : filters.showPast,
                    })
                  }
                  className="mr-2"
                />{" "}
                Upcoming Events
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters({
                      showUpcoming: filters.showUpcoming
                        ? false
                        : filters.showUpcoming,
                      showPast: !filters.showPast,
                    })
                  }
                  className="mr-2"
                />{" "}
                Past Events
              </label>
            </div>

            {/* Date Picker */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Check by Dates</h4>
              <div className="p-1 md:p-0">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  highlightDates={eventDates}
                  dayClassName={(date) =>
                    isEventDate(date) ? "highlighted-event-date" : undefined
                  }
                  calendarClassName="custom-calendar"
                  className="w-full"
                />
              </div>
            </div>

            <button
              onClick={handleApplyFilters}
              className="w-full bg-teal-500 text-white py-2 rounded-3xl hover:bg-teal-600 transition duration-300 mt-auto"
            >
              Apply All Filters
            </button>
          </div>
        </div>

        {/* Map Section */}
        <div className="w-full md:w-4/5 p-4">
          <MapComponent
            selectedDate={selectedDate}
            setEventDates={setEventDates}
            filters={filters}
            applyFilters={applyFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default EventPage;
