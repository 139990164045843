/* global google */
import React, { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import api from "../api";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 12.9716, // Default latitude (Bangalore)
  lng: 77.5946, // Default longitude
};

const MapComponent = ({
  selectedDate,
  setEventDates,
  filters,
  applyFilters,
}) => {
  const [events, setEvents] = useState([]);
  const mapRef = useRef(null); // Reference to the map container
  const mapInstanceRef = useRef(null); // Reference to the actual Google Maps instance
  const infoWindowRef = useRef(null); // Reference for InfoWindow

  // Fetch events and store event dates
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        console.log("api", process.env.REACT_APP_API_ENDPOINT);
        const response = await api.get(`/api/events`);
        console.log(response.data);
        const eventData = response.data.data;
        setEvents(eventData);

        // Extract event dates and pass them up to the parent
        const eventDates = eventData.map((event) => new Date(event.date));
        setEventDates(eventDates);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [setEventDates]);

  // Initialize the Google Maps API and map
  useEffect(() => {
    const loader = new Loader({
      apiKey:
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
        "AIzaSyCgU8ok_12P2PGbSmGKFdRyFyRQF0FNIU8",
      version: "weekly",
      libraries: ["places"], // Ensure the "places" library is loaded
    });

    loader
      .load()
      .then(() => {
        if (mapRef.current && !mapInstanceRef.current) {
          mapInstanceRef.current = new google.maps.Map(mapRef.current, {
            center,
            zoom: 12,
            mapId: "cff9b68ed09c5011",
          });
          infoWindowRef.current = new google.maps.InfoWindow();
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps:", error);
      });
  }, []);

  // Update markers based on filters and selected date
  useEffect(() => {
    if (applyFilters && mapInstanceRef.current) {
      // Clear previous markers
      mapInstanceRef.current.markers?.forEach((marker) => marker.setMap(null));

      // Filter events based on selectedDate and filters
      const filteredEvents = events.filter((event) => {
        const eventDate = new Date(event.date);
        const isUpcoming = eventDate > new Date();
        const isPast = eventDate < new Date();

        const isSelectedDate =
          selectedDate &&
          eventDate.getDate() === selectedDate.getDate() &&
          eventDate.getMonth() === selectedDate.getMonth() &&
          eventDate.getFullYear() === selectedDate.getFullYear();

        return (
          ((filters.showUpcoming && isUpcoming) ||
            (filters.showPast && isPast)) &&
          (!selectedDate || isSelectedDate)
        );
      });

      // Add markers for the filtered events
      const markers = filteredEvents.map((event) => {
        const { latitude, longitude } = event.eventHotspot ?? center;

        // Log lat/long values for debugging
        console.log("Latitude:", latitude, "Longitude:", longitude);

        // Check if lat/long are numbers
        if (isNaN(latitude) || isNaN(longitude)) {
          console.error("Invalid lat/long values:", latitude, longitude);
          return null; // Skip creating a marker if the lat/long is invalid
        }

        const marker = new google.maps.Marker({
          position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          map: mapInstanceRef.current,
          title: event.event_name,
        });

        marker.addListener("click", () => {
          infoWindowRef.current.setContent(`
            <div class="info-window-content">
              <h2 class="text-[#029E9B] text-lg font-normal border-b border-[#E1E0E0]">
                ${event.eventHotspot.property_name}, ${
            event.eventHotspot.property_address
          }
              </h2>
              <div class="pt-5">
                <p><span class="font-light">Time:</span> <strong> ${
                  event.start_time
                } - ${event.end_time}</strong></p>
                <p><span class="font-light">Schedule: </span> <strong>${new Date(
                  event.date
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}</strong></p>
              </div>
              <a href="https://maps.google.com/?q=${latitude},${longitude}" target="_blank" rel="noopener noreferrer" class="text-[#FF9519] font-light underline mt-2 inline-block">
                Check out the address <img class="inline-block" src="images/Vector-ArrowRight.svg">
              </a>
            </div>
          `);
          infoWindowRef.current.open(mapInstanceRef.current, marker);
        });

        return marker;
      });

      // Filter out null markers (invalid lat/long) and store the valid ones
      mapInstanceRef.current.markers = markers.filter(
        (marker) => marker !== null
      );
    }
  }, [applyFilters, selectedDate, events, filters]);

  return <div ref={mapRef} style={mapContainerStyle}></div>;
};

export default MapComponent;
