import React from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    navigate('/');
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white shadow-md rounded-lg text-center">
      <h2 className="text-2xl font-bold mb-6">Dashboard</h2>
      <p className="mb-6">Welcome to your dashboard!</p>
      <button
        onClick={handleLogout}
        className="w-full py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700"
      >
        Logout
      </button>
    </div>
  );
};

export default Dashboard;