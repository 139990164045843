import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import EmailLogin from "./components/EmailLogin";
import CreateBrand from "./components/CreateBrand";
import Dashboard from "./components/Dashboard"; // Your home component
import ProtectedRoute from "./components/ProtectedRoute"; // If using protected routes
import EventPage from "./pages/EventCalendar"

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<EmailLogin />} />
          <Route path="/create-brand" element={<CreateBrand />} />
          <Route path="/event-calendar" element={<EventPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          {/* Add other routes as needed */}
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
